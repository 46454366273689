@import url(https://fonts.googleapis.com/css2?family=Fasthand&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courgette&family=Fasthand&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,400i,700);

.project-container2 .project-heading{
    color: #CCD6F6;
}
.project-container{
    /* background-color: white; */
    margin-top: 170px;
    text-align: left;
    text-align: initial;
    width: 100%;
    /* margin-bottom: 200px; */
    position: relative;
}
.project-container2{
    /* background-color: red; */
    color: #CCD6F6;
    text-align: inherit;
    margin-left: 240px;
    width: 80%;
}
.project1-abt ul li{
    
    text-align: justify;
    width: 45%;
    margin-bottom: 20px;
}
.project1-abt ul li a{
    color: #64ffda;
}
.project-img  {
    width: 500px;
    /* width: 200px;
    height: 200px; */
    /* background-color: black; */
    position: relative;
    left: 600px;
    bottom:250px;
}
.project-img img{
    width:450px ;
    border: 1px solid #64ffda;
    border-radius: 5px;
}
.project-heading::after{
    content: ''; 
        display: block;
        width: 580px;
        height: 0.5px;
        background: #CCD6F6;
        position: relative;
        bottom: 15px;
        left: 320px;
        opacity: 0.2;
        z-index: -2;
}
@media (max-width:600px)  {
    .project-heading{
        width: 400px;
    }
    .project-heading::after{
        display: none;
           
    }
    .project-img  {
        /* width: 500px; */
        /* width: 200px;
        height: 200px; */
        /* background-color: black; */
        width: 400px;
        /* style={{ width: "400px", border: '2px solid #CCD6F6', borderRadius: "5px" }} */
        position: relative;
        left: 0px;
        bottom:0px;
    }
    .project-img img{
        width:350px ;
        border: 1px solid #CCD6F6;
        border-radius: 5px;
        margin-left: 30px;
    }
    .project-container2{
        width: 400px;
        margin-top: 100px;
        text-align: inherit;
        margin-left: 24px;
    }
    .project1-abt{
        /* margin-left: 5px; */
        width: 350px;
    }
    .project1-abt ul li{
        margin-left: 5px;
        width: 350px;
    }
}
.about-container2 .about-heading{
    color: #CCD6F6;
}
.about-container{
    text-align: left;
    text-align: initial;
    width: 100%;
    position: relative;
    height: 500px;
}
.about-container2{
    text-align: inherit;
    margin-left: 240px;
}
.about-container2 p{
    font-size: 20px;
    color: #CCD6F6;
    padding: 20px;
    
}
.about-pragraph{
    position: absolute;
    text-align: justify;
    left:550px;
}
.skills-list{
    position: absolute;
    top: 250px;
    left: 550px;
    
}
.skills-list li{
    list-style-type:circle;
    margin-left: 30px;
}
.skills-list ul{
    -webkit-column-count: 2;
            column-count: 2;
    color: #CCD6F6;

}
ul li::before {
    content: "\2022";  
    color: #64ffda; 
    font-weight: bold; 
    display: inline-block;
    width: 1em; 
    margin-left: -1em; 
  }
.myImg{  
    float: left;
    width: 300px;
    cursor: pointer;
    box-shadow: none !important;
    border: none !important;
    filter: grayscale();
    -webkit-filter: grayscale(1); 
}
.img{
    box-shadow: 8px 8px #64ffda;
    transition: box-shadow 1s;
}
.img:hover{
    box-shadow: none;
}
.myImg:hover {
    filter: none;
      -webkit-filter: grayscale(0);
      -webkit-transform: scale(1) !important;
              transform: scale(1) !important;
    }
.about-heading::after{
        content: ''; 
        display: block;
        width:700px;
        height: 0.5px;
        background: #CCD6F6;
        position: relative;
        bottom: 15px;
        left: 200px;
        opacity: 0.2;
        z-index: -2;
}
.myImg img{
    border-radius: 5% !important;
    
}
@media (max-width:480px) {
        .myImg{  
            float: left;
            width: 300px;
            cursor: pointer;
            box-shadow: none !important;
            border: none !important;
            filter: rgb(141, 224, 173); 
            -webkit-filter: grayscale(1); 
        }
        .about-container{
            height: 650px;
        }
        .about-container2{
            margin-top: 100px;
            /* text-align: inherit; */
            margin-left: 24px;
        }
        .about-container2 p{
            /* font-size: 20px; */
            color: #CCD6F6;
            /* padding: 20px; */
            /* margin-left: 400px; */
            position: absolute;
            width: 400px;
            top: 250px;
            right: 150px;
            text-align: left;
            text-align: initial;
        }
        .about-pragraph{
            position: absolute;
            text-align: justify !important;
            left:550px;
        }
        .skills-list{
            position: absolute;
            top: 240px;
            left: 550px;
            
        }
        .skills-list li{
            list-style-type:circle;
            margin-left: 20px;
        }
        .skills-list ul{
            -webkit-column-count: 2;
                    column-count: 2;
            color: #CCD6F6;
            position: absolute;
            width: 400px;
            top: 390px;
            right: 150px;
        }
        .about-heading::after{
                display: none;
        }

}
* {
	box-sizing: border-box;
}

body {
	margin: 0%;
}

.navbar {
	height: 60px;
	width: 100%;
  position: relative;
  /* background-color: #112240; */
  background-color: #0a192f;
  /* background-image: -webkit-linear-gradient(top left, #264653 0%, #2a9d8f 25%, #e9c46a 50%, #f4a261 75%, #e76f51 100%);
	background-image: -moz-linear-gradient(top left, #264653 0%, #2a9d8f 25%, #e9c46a 50%, #f4a261 75%, #e76f51 100%);
	background-image: linear-gradient(top left, #264653 0%, #2a9d8f 25%, #e9c46a 50%, #f4a261 75%, #e76f51 100%); */
}

.navbar-header {
	display: inline;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
}

.navbar-title {
	position: relative;
	display: inline-block;
	font-size: 26px;
  font-weight: bolder;
  padding-left: 50%;
	/* color: #000000; */
  color: #64ffda;
  font-family: 'Courgette', cursive;
  text-decoration: none;
}

.navbar-responsive-button {
	display: none;
  padding-right: 2%;
}

.navbar-links {
	display: inline;
	float: right;
	font-size: 18px;
	position: relative;
	top: 50%;
	-webkit-transform: translate(0, -50%);
	        transform: translate(0, -50%);
}

.navbar-links > a {
  margin-right: 20px;
	display: inline-block;
	padding: 10px 10px 10px 10px;
	text-decoration: none;
	color: #000000;
  color: #64ffda;
  font-weight: bold;

}

.navbar-links > a:hover {
	/* background-color: rgba(0, 0, 0, 0.5); */
  color: white
}

#navbar-checkbox {
	display: none;
}

@media (max-width:600px) {
	.navbar-responsive-button {
		display: inline-block;
		position: relative;
		float: right;
		top: 50%;
		-webkit-transform: translate(0, -50%);
		        transform: translate(0, -50%);
	}

	.navbar-responsive-button > label {
		display: inline-block;
		width: 50px;
		height: 50px;
		padding: 13px;
	}

  .navbar-responsive-button > label:hover {
		background-color: rgba(0, 0, 0, 0.5);
    color: #d3d3d3
	}
  
  #navbar-checkbox:checked ~ .navbar-responsive-button > label {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.hamburger-icon {
		display: block;
		height: 10px;
		width: 25px;
		border-top: 2px solid #000000;
	}

	.navbar-links {
		position: relative;
		display: block;
		width: 100%;
		height: 0%;
    top: 50%;
		left: 0%;
    -webkit-transform: translate(0, -6%);
            transform: translate(0, -6%);
    transition: all 1s ease-out;
    overflow-y: hidden;
    
	}

	.navbar-links > a {
		display: block;
		width: 100%;
	}

	#navbar-checkbox:checked ~ .navbar-links {
		height: 50vh;
    overflow-y: auto;
	}

	#navbar-checkbox:not(:checked) ~ .navbar-links {
		height: 0%;
	}
}

.navbar-links a::after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 2px;
  background-color:#64ffda ;
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
}
div a:hover::after {
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}
body{
    background-color: #0A192F;
}
a{
    text-decoration: none;
}
img{
    width: 100%;
}
.home-container{
    background-image: url(/static/media/main.fee95fa2.svg);
    background-repeat:no-repeat;
    /* background-position: right; */
    background-position: right 2% bottom 45%;
    height: 670px;
    text-align: left;
    text-align: initial;
    margin-top: 90px;
    width: 100%;
    position: relative;
}
.home-container2{
    text-align: inherit;
    margin-left: 240px;
}
.home-container .home-paragraph{
    color: #64ffda;
    /* margin-bottom: 50px; */
}
.home-container .home-paragraph-about{
    color: #CCD6F6;
    
}
.home-container .home-heading1{
    font-size: 80px;
    color: #CCD6F6;
    margin-top: 20px;
    margin-bottom: 30px;
}
.home-container .home-heading2{
    font-size: 70px;
    color: #a3accc;
    margin-top: 30px;
}
.home-btn{
    
    margin-top: 20px;
    background-color:#0A192F;
    font-size: 20px;
    padding: 10px;
    /* font-family: cursive; */
    /* color: #CCD6F6; */
    color: #64ffda;
    border-radius: 8px;
    border: 1px groove #64ffda;
    cursor: pointer;
}
.home-btn:hover{
    background-color: #64ffda;
    color: #112240;
    box-shadow: 0px 5px 10px #64ffda;
    
}
@media (max-width:600px) {
    .home-container{
        background-image: none;
        width: 100%;
    }
    .home-container2{
        /* text-align: inherit; */
        width: 300px;
        margin-left: 24px;
       
        
    }
    .home-heading1{
        width: 300px;
    }
    .home-btn{
        margin-top: 20px;
        background-color:#0A192F;
        font-size: 15px;
        padding: 10px;
        font-family: cursive;
        color: #64ffda;
        border-radius: 10px;
        border: 1px groove #64ffda;
        cursor: pointer;
    }
}
footer{
    height: 450px;
    background-color: #0A192F;
   
}
.icons a{
    font-size: 30px;
    color: #64ffda;
    margin: 12px;
}
.icons a:hover{
    color: #CCD6F6;
}
.footer-Heading{
    color: #CCD6F6;
    
}
.footer-paragraph{
    color: #CCD6F6;
    font-size: 20px;
}
.footer-container{
    padding: 50px;
    text-align: center;
}
.footer-btn{
    margin-top: 15px;
    background-color:#0A192F;
    font-size: 20px;
    padding: 10px;
    font-family: cursive;
    color: #64ffda;
    border-radius: 10px;
    border: 1px solid #64ffda;
    cursor: pointer;
}
.footer-btn:hover{
    background-color: #64ffda;
    color: #112240;
    
}
*{box-sizing:border-box;}
h1{
  font-size:2.5em;
  font-weight:700;
  color: #64ffda;
  
}
.loader-wrapper{
  height:100%;
  width:100%;
  display: flex;
  align-items:center;
}
.loader-container{
  width:100%;
  display: flex;
  justify-content:center;
  position:relative;
  top:300px
}
.lt{
  width:100px;
  position:absolute;
  font-family: Montserrat, sans-serif;
}
#ltNd{
  -webkit-transform:rotateZ(180deg);
          transform:rotateZ(180deg);
}
.loader{
    font-family: Montserrat, sans-serif;
  background:-webkit-linear-gradient(45deg,red,orange);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
  position:absolute;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-animation: loading 1s ease-in-out infinite;
          animation: loading 1s ease-in-out infinite;
}

@-webkit-keyframes loading{
  0%{-webkit-transform: rotateX(0deg);transform: rotateX(0deg);}
  50%{-webkit-transform: rotateX(180deg);transform: rotateX(180deg);}
  100%{-webkit-transform: rotateX(360deg);transform: rotateX(360deg);}
}

@keyframes loading{
  0%{-webkit-transform: rotateX(0deg);transform: rotateX(0deg);}
  50%{-webkit-transform: rotateX(180deg);transform: rotateX(180deg);}
  100%{-webkit-transform: rotateX(360deg);transform: rotateX(360deg);}
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #495670;
  border-radius: 10px;
}
*{box-sizing:border-box;}
/* html, body{
  height:100vh;
  color:#fff;
  font-family: Montserrat, sans-serif;
} */
.loader-container h1{
  font-size:2.5em;
  font-weight:700;
  color: #64ffda;
  
}
.loader-wrapper{
  height:100%;
  width:100%;
  display: flex;
  align-items:center;
}
.loader-container{
  width:100%;
  display: flex;
  justify-content:center;
  position:relative;
  top:300px
}
.lt{
  width:100px;
  position:absolute;
  font-family: Montserrat, sans-serif;
}
#ltNd{
  -webkit-transform:rotateZ(180deg);
          transform:rotateZ(180deg);
}
.loader{
    font-family: Montserrat, sans-serif;
  background:-webkit-linear-gradient(45deg,red,orange);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
  position:absolute;
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-animation: loading 1s ease-in-out infinite;
          animation: loading 1s ease-in-out infinite;
}

@-webkit-keyframes loading{
  0%{-webkit-transform: rotateX(0deg);transform: rotateX(0deg);}
  50%{-webkit-transform: rotateX(180deg);transform: rotateX(180deg);}
  100%{-webkit-transform: rotateX(360deg);transform: rotateX(360deg);}
}

@keyframes loading{
  0%{-webkit-transform: rotateX(0deg);transform: rotateX(0deg);}
  50%{-webkit-transform: rotateX(180deg);transform: rotateX(180deg);}
  100%{-webkit-transform: rotateX(360deg);transform: rotateX(360deg);}
}
