.about-container2 .about-heading{
    color: #CCD6F6;
}
.about-container{
    text-align: initial;
    width: 100%;
    position: relative;
    height: 500px;
}
.about-container2{
    text-align: inherit;
    margin-left: 240px;
}
.about-container2 p{
    font-size: 20px;
    color: #CCD6F6;
    padding: 20px;
    
}
.about-pragraph{
    position: absolute;
    text-align: justify;
    left:550px;
}
.skills-list{
    position: absolute;
    top: 250px;
    left: 550px;
    
}
.skills-list li{
    list-style-type:circle;
    margin-left: 30px;
}
.skills-list ul{
    column-count: 2;
    color: #CCD6F6;

}
ul li::before {
    content: "\2022";  
    color: #64ffda; 
    font-weight: bold; 
    display: inline-block;
    width: 1em; 
    margin-left: -1em; 
  }
.myImg{  
    float: left;
    width: 300px;
    cursor: pointer;
    box-shadow: none !important;
    border: none !important;
    filter: grayscale();
    -webkit-filter: grayscale(1); 
}
.img{
    box-shadow: 8px 8px #64ffda;
    transition: box-shadow 1s;
}
.img:hover{
    box-shadow: none;
}
.myImg:hover {
    filter: none;
      -webkit-filter: grayscale(0);
      transform: scale(1) !important;
    }
.about-heading::after{
        content: ''; 
        display: block;
        width:700px;
        height: 0.5px;
        background: #CCD6F6;
        position: relative;
        bottom: 15px;
        left: 200px;
        opacity: 0.2;
        z-index: -2;
}
.myImg img{
    border-radius: 5% !important;
    
}
@media (max-width:480px) {
        .myImg{  
            float: left;
            width: 300px;
            cursor: pointer;
            box-shadow: none !important;
            border: none !important;
            filter: rgb(141, 224, 173); 
            -webkit-filter: grayscale(1); 
        }
        .about-container{
            height: 650px;
        }
        .about-container2{
            margin-top: 100px;
            /* text-align: inherit; */
            margin-left: 24px;
        }
        .about-container2 p{
            /* font-size: 20px; */
            color: #CCD6F6;
            /* padding: 20px; */
            /* margin-left: 400px; */
            position: absolute;
            width: 400px;
            top: 250px;
            right: 150px;
            text-align: initial;
        }
        .about-pragraph{
            position: absolute;
            text-align: justify !important;
            left:550px;
        }
        .skills-list{
            position: absolute;
            top: 240px;
            left: 550px;
            
        }
        .skills-list li{
            list-style-type:circle;
            margin-left: 20px;
        }
        .skills-list ul{
            column-count: 2;
            color: #CCD6F6;
            position: absolute;
            width: 400px;
            top: 390px;
            right: 150px;
        }
        .about-heading::after{
                display: none;
        }

}