.project-container2 .project-heading{
    color: #CCD6F6;
}
.project-container{
    /* background-color: white; */
    margin-top: 170px;
    text-align: initial;
    width: 100%;
    /* margin-bottom: 200px; */
    position: relative;
}
.project-container2{
    /* background-color: red; */
    color: #CCD6F6;
    text-align: inherit;
    margin-left: 240px;
    width: 80%;
}
.project1-abt ul li{
    
    text-align: justify;
    width: 45%;
    margin-bottom: 20px;
}
.project1-abt ul li a{
    color: #64ffda;
}
.project-img  {
    width: 500px;
    /* width: 200px;
    height: 200px; */
    /* background-color: black; */
    position: relative;
    left: 600px;
    bottom:250px;
}
.project-img img{
    width:450px ;
    border: 1px solid #64ffda;
    border-radius: 5px;
}
.project-heading::after{
    content: ''; 
        display: block;
        width: 580px;
        height: 0.5px;
        background: #CCD6F6;
        position: relative;
        bottom: 15px;
        left: 320px;
        opacity: 0.2;
        z-index: -2;
}
@media (max-width:600px)  {
    .project-heading{
        width: 400px;
    }
    .project-heading::after{
        display: none;
           
    }
    .project-img  {
        /* width: 500px; */
        /* width: 200px;
        height: 200px; */
        /* background-color: black; */
        width: 400px;
        /* style={{ width: "400px", border: '2px solid #CCD6F6', borderRadius: "5px" }} */
        position: relative;
        left: 0px;
        bottom:0px;
    }
    .project-img img{
        width:350px ;
        border: 1px solid #CCD6F6;
        border-radius: 5px;
        margin-left: 30px;
    }
    .project-container2{
        width: 400px;
        margin-top: 100px;
        text-align: inherit;
        margin-left: 24px;
    }
    .project1-abt{
        /* margin-left: 5px; */
        width: 350px;
    }
    .project1-abt ul li{
        margin-left: 5px;
        width: 350px;
    }
}