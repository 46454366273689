@import url("https://fonts.googleapis.com/css?family=Montserrat:400,400i,700");
*{box-sizing:border-box;}
/* html, body{
  height:100vh;
  color:#fff;
  font-family: Montserrat, sans-serif;
} */
.loader-container h1{
  font-size:2.5em;
  font-weight:700;
  color: #64ffda;
  
}
.loader-wrapper{
  height:100%;
  width:100%;
  display: flex;
  align-items:center;
}
.loader-container{
  width:100%;
  display: flex;
  justify-content:center;
  position:relative;
  top:300px
}
.lt{
  width:100px;
  position:absolute;
  font-family: Montserrat, sans-serif;
}
#ltNd{
  transform:rotateZ(180deg);
}
.loader{
    font-family: Montserrat, sans-serif;
  background:-webkit-linear-gradient(45deg,red,orange);
  -webkit-background-clip:text;
  -webkit-text-fill-color:transparent;
  position:absolute;
  transform: rotateX(0deg);
  transform-style: preserve-3d;
  animation: loading 1s ease-in-out infinite;
}

@keyframes loading{
  0%{transform: rotateX(0deg);}
  50%{transform: rotateX(180deg);}
  100%{transform: rotateX(360deg);}
}