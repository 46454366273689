footer{
    height: 450px;
    background-color: #0A192F;
   
}
.icons a{
    font-size: 30px;
    color: #64ffda;
    margin: 12px;
}
.icons a:hover{
    color: #CCD6F6;
}
.footer-Heading{
    color: #CCD6F6;
    
}
.footer-paragraph{
    color: #CCD6F6;
    font-size: 20px;
}
.footer-container{
    padding: 50px;
    text-align: center;
}
.footer-btn{
    margin-top: 15px;
    background-color:#0A192F;
    font-size: 20px;
    padding: 10px;
    font-family: cursive;
    color: #64ffda;
    border-radius: 10px;
    border: 1px solid #64ffda;
    cursor: pointer;
}
.footer-btn:hover{
    background-color: #64ffda;
    color: #112240;
    
}