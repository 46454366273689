body{
    background-color: #0A192F;
}
a{
    text-decoration: none;
}
img{
    width: 100%;
}
.home-container{
    background-image: url('main.svg');
    background-repeat:no-repeat;
    /* background-position: right; */
    background-position: right 2% bottom 45%;
    height: 670px;
    text-align: initial;
    margin-top: 90px;
    width: 100%;
    position: relative;
}
.home-container2{
    text-align: inherit;
    margin-left: 240px;
}
.home-container .home-paragraph{
    color: #64ffda;
    /* margin-bottom: 50px; */
}
.home-container .home-paragraph-about{
    color: #CCD6F6;
    
}
.home-container .home-heading1{
    font-size: 80px;
    color: #CCD6F6;
    margin-top: 20px;
    margin-bottom: 30px;
}
.home-container .home-heading2{
    font-size: 70px;
    color: #a3accc;
    margin-top: 30px;
}
.home-btn{
    
    margin-top: 20px;
    background-color:#0A192F;
    font-size: 20px;
    padding: 10px;
    /* font-family: cursive; */
    /* color: #CCD6F6; */
    color: #64ffda;
    border-radius: 8px;
    border: 1px groove #64ffda;
    cursor: pointer;
}
.home-btn:hover{
    background-color: #64ffda;
    color: #112240;
    box-shadow: 0px 5px 10px #64ffda;
    
}
@media (max-width:600px) {
    .home-container{
        background-image: none;
        width: 100%;
    }
    .home-container2{
        /* text-align: inherit; */
        width: 300px;
        margin-left: 24px;
       
        
    }
    .home-heading1{
        width: 300px;
    }
    .home-btn{
        margin-top: 20px;
        background-color:#0A192F;
        font-size: 15px;
        padding: 10px;
        font-family: cursive;
        color: #64ffda;
        border-radius: 10px;
        border: 1px groove #64ffda;
        cursor: pointer;
    }
}