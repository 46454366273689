@import url('https://fonts.googleapis.com/css2?family=Fasthand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Fasthand&display=swap');
* {
	box-sizing: border-box;
}

body {
	margin: 0%;
}

.navbar {
	height: 60px;
	width: 100%;
  position: relative;
  /* background-color: #112240; */
  background-color: #0a192f;
  /* background-image: -webkit-linear-gradient(top left, #264653 0%, #2a9d8f 25%, #e9c46a 50%, #f4a261 75%, #e76f51 100%);
	background-image: -moz-linear-gradient(top left, #264653 0%, #2a9d8f 25%, #e9c46a 50%, #f4a261 75%, #e76f51 100%);
	background-image: linear-gradient(top left, #264653 0%, #2a9d8f 25%, #e9c46a 50%, #f4a261 75%, #e76f51 100%); */
}

.navbar-header {
	display: inline;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
}

.navbar-title {
	position: relative;
	display: inline-block;
	font-size: 26px;
  font-weight: bolder;
  padding-left: 50%;
	/* color: #000000; */
  color: #64ffda;
  font-family: 'Courgette', cursive;
  text-decoration: none;
}

.navbar-responsive-button {
	display: none;
  padding-right: 2%;
}

.navbar-links {
	display: inline;
	float: right;
	font-size: 18px;
	position: relative;
	top: 50%;
	transform: translate(0, -50%);
}

.navbar-links > a {
  margin-right: 20px;
	display: inline-block;
	padding: 10px 10px 10px 10px;
	text-decoration: none;
	color: #000000;
  color: #64ffda;
  font-weight: bold;

}

.navbar-links > a:hover {
	/* background-color: rgba(0, 0, 0, 0.5); */
  color: white
}

#navbar-checkbox {
	display: none;
}

@media (max-width:600px) {
	.navbar-responsive-button {
		display: inline-block;
		position: relative;
		float: right;
		top: 50%;
		transform: translate(0, -50%);
	}

	.navbar-responsive-button > label {
		display: inline-block;
		width: 50px;
		height: 50px;
		padding: 13px;
	}

  .navbar-responsive-button > label:hover {
		background-color: rgba(0, 0, 0, 0.5);
    color: #d3d3d3
	}
  
  #navbar-checkbox:checked ~ .navbar-responsive-button > label {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.hamburger-icon {
		display: block;
		height: 10px;
		width: 25px;
		border-top: 2px solid #000000;
	}

	.navbar-links {
		position: relative;
		display: block;
		width: 100%;
		height: 0%;
    top: 50%;
		left: 0%;
    transform: translate(0, -6%);
    transition: all 1s ease-out;
    overflow-y: hidden;
    
	}

	.navbar-links > a {
		display: block;
		width: 100%;
	}

	#navbar-checkbox:checked ~ .navbar-links {
		height: 50vh;
    overflow-y: auto;
	}

	#navbar-checkbox:not(:checked) ~ .navbar-links {
		height: 0%;
	}
}

.navbar-links a::after {
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 2px;
  background-color:#64ffda ;
  transform-origin: bottom right;
  transition: transform 0.5s ease;
  transform: scaleX(0);
}
div a:hover::after {
  transform-origin: bottom left;
  transform: scaleX(1);
}